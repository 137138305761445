import React, { useState, useEffect } from "react";
import { Carousel, Modal, Avatar, Segmented } from "antd";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  AiOutlineSearch,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs"
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../routes/route.constants";
import { IMAGES } from "../../constant/images";
import { getCategoriesAsync } from "../../atoms/categories/category.api";
import { getSlidesAsync } from "../../atoms/slides/slide.api";
import { message } from "antd";
import { parse } from "query-string";
import { getPostsAsync } from "../../atoms/posts/post.api";
import LoadingPage from "./Loading";

function App() {
  const navigate = useNavigate();
  const { active } = parse(useLocation().search);
  const [messageApi, contextHolder] = message.useMessage();
  const [visibleModal, setVisibleModal] = useState(false);
  const [addToCartButton, setAddToCartAction] = useState(false);
  const [categories, setCategories] = useState([]);
  const [images, setImage] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [data, setData] = useState([]);
  const [dataShowing, setDataShowing] = useState([]);

  useEffect(() => {
    if (active) {
      setActiveCategory(active);
    } else {
      setActiveCategory("");
    }
  }, [active]);
  

  const initial = () => {
    getCategoriesAsync({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        if (items.length) {
          setCategories(items);
        }
      },
      error: (err) => console.log(err.message),
      param: {},
    });
    getSlidesAsync({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        if (items.length) {
          setImage(items);
        }
      },
      error: (err) => console.log(err.message),
      param: {},
    });
    getPostsAsync({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        if (items.length) {
          setData(items);
        }
      },
      error: (err) => console.log(err.message),
      param: {},
    });
  };

  useEffect(() => {
    initial();
  }, []);

  useEffect(()=> {
    if(active && data.length){
      const showingData = data.filter((item) => item.category.find((data)=> data === active)) 
      setDataShowing(showingData)
    }else{
      setDataShowing(data)
    }
  },[data, active])

  const addToFavorite = () => {
    messageApi.open({
      type: "success",
      content: "បានដាក់ចូលទៅកន្លែងដែលចូលចិត្ត !",
    });
  };

  const changeActive = (activeId) => {
    if (activeId) {
      navigate(`?active=${activeId}`);
    } else {
      navigate(`/`);
    }
  };
  if (!categories.length && !data.length) {
    return <LoadingPage />
  };

  return (
    <div className="bg-white h-[100vh]">
      {contextHolder}
      <div className="max-w-[450px] mx-auto">
        {/* Header App */}
        <div className="mt-4 bg-white p-3 flex justify-between items-center sticky top-0 z-10">
          <button>
            <span className="text-red-400 uppercase text-md p-2 bg-red-100 font-bold rounded-md text-center items-center">
              KH
            </span>
            <span className="text-red-400 text-md p-2 font-bold rounded-md text-center items-center">
              DressUp
            </span>
          </button>
          <div className="w=[100%] text-center flex items-center">
            <Link to={"/search"}>
              <AiOutlineSearch className="text-red-400 w-[28px] h-[28px] cursor-pointer mr-2 text-2xl	hover:text-red-300" />
            </Link>
            <button type="button" className="relative inline-flex items-center p-2">
              <BsCartCheck className="text-red-400 w-[28px] h-[28px] cursor-pointer mr-2 text-2xl	hover:text-red-300" />
              {addToCartButton && (
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -right-[-10px] dark:border-gray-900">1</div>
              )}
            </button>
            <GiHamburgerMenu
              onClick={() => setVisibleModal(true)}
              className="text-red-400 w-[28px] h-[28px] cursor-pointer mr-1 text-2xl	hover:text-red-300"
            />
          </div>
        </div>
        {/* Banner App */}
        {images.length > 0 ? (
          <Carousel autoplay className="mx-4 mt-4 cursor-pointer">
            {images.map((item) => {
              return (
                <div key={item.rank} className="rounded-lg">
                  <img
                    src={item.img}
                    className="h-[180px] text-center items-center m-0 rounded-lg bg-red-100 text-red-500"
                    alt="thumbnail"
                  />
                </div>
              );
            })}
          </Carousel>
        ) : (
          ""
        )}
        {/* Menu App */}
        <div
          className="flex items-center sticky top-14 z-10 mt-4 py-3 hide-scroll-bar bg-white mx-3 "
          style={{ overflowX: "scroll" }}
        >
          <button
            onClick={() => changeActive()}
            className={`text-red-500 font-bold bg-red-50 hover:bg-red-500 hover:text-white text-sm p-2 rounded-md text-center mr-4 whitespace-nowrap min-w-[80px] ${activeCategory === "" ? "bg-red-500 text-white" : ""
              }`}
          >
            ទាំងអស់
          </button>
          {categories.length > 0
            ? categories.map((item) => (
              <button
                onClick={() => changeActive(item.id)}
                key={item.id}
                className={`text-red-500 font-bold bg-red-50 hover:bg-red-500 hover:text-white text-sm p-2 rounded-md text-center mr-4 last:mr-0 whitespace-nowrap min-w-[80px] ${item.id === activeCategory ? "bg-red-500 text-white" : ""
                  }`}
              >
                {item.title}
              </button>
            ))
            : ""}
        </div>

        {/* Post List */}
        {dataShowing.length === 0 ? <div className="w-[100%] flex justify-center">
          <div>
            <img className="h-[150px] mt-20" src={IMAGES.NOT_FOUND.default} alt="404" />
            <h2 className="text-center mt-5 text-2xl font-bold text-red-500">រកមិនឃើញផលិតផល</h2>
          </div>
        </div> : ""}
        <div className="grid gap-4 grid-cols-2 grid-rows-2 p-4">
          {dataShowing?.map((item) => {
            return (
              <div key={item.id} className="h-[270px] w-full hover:cursor-pointer mb-4 mt-4">
                <Link to={`${ROUTES.PRODUCT.replace(":id", item.id)}`}>
                  <img
                    className="h-[70%] w-[100%] rounded-md"
                    src={IMAGES[1]}
                    alt="thumbnail-logo"
                  />
                  <div className="">
                    <h6 className="whitespace-nowrap text-ellipsis overflow-hidden w-[185px]">{item.title}</h6>
                    <div className="my-2">
                      <span className="text-red-500 font-bold">${item.salePrice || 0}</span>{" "}
                      <span className="text-gray-400 ml-4 line-through">
                        ${item.price || 0}
                      </span>
                    </div>
                  </div>
                </Link>
                <div className="flex flex-row-reverse items-center">
                  <button
                    onClick={() => setAddToCartAction(true)}
                    className="text-red-500 bg-red-50 hover:bg-red-500 hover:text-white text-sm font-bold p-2 rounded-md text-center"
                  >
                    បញ្ជារទិញ
                  </button>
                  <button
                    onClick={() => addToFavorite()}
                    className="text-red-500 bg-red-50 hover:bg-red-500 hover:text-white text-lg font-bold p-2 rounded-md text-center mr-4"
                  >
                    <AiOutlineHeart />
                  </button>
                </div>
              </div>
            )
          })}
        </div>

        {/* Order Button */}
        {addToCartButton && (
          <div className="fixed max-w-[450px] w-[100%] z-10 bottom-0 p-3 bg-white">
            <div className="max-w-[450px] h-[50px]  bottom-0 flex justify-between space-x-4">
              <button
                onClick={() => setAddToCartAction(false)}
                className="w-[20%] text-gray-500 uppercase bg-white text-md p-3 border border-red-200 font-bold rounded-lg cursor-pointer"
              >
                <AiOutlineShoppingCart className="text-xl  items-center mx-auto text-red-400" />
              </button>
              <button
                onClick={() => setAddToCartAction(false)}
                className="w-[80%] text-red-400 uppercase text-md p-3 bg-red-100 font-bold rounded-lg text-center items-center cursor-pointer"
              >
                កម្មង់ឥលូវនេះ
              </button>
            </div>
          </div>
        )}

        {/* Button Load more */}
        <div className="w-[100%] h-[20px] text-center">
          {/* <button className='text-red-400 uppercase text-md p-2 border border-red-100 m-4 rounded-md text-center items-center'>Load more...</button> */}
        </div>

        {/* Burger Menu List */}
        {visibleModal && (
          <Modal
            className="ant-modal-cus"
            footer={null}
            width={450}
            title="Menu"
            open={visibleModal}
            onCancel={() => setVisibleModal(false)}
          >
            <div className="p-3 bg-gray-100 rounded-md mb-4 space-x-4 flex">
              <button className="text-center text-sm font-bold uppercase w-[100%] h-[80px] text-gray-600 bg-white hover:bg-gray-200 hover:text-gray-900 p-2 rounded-md">
                Home
              </button>
              <button
                disabled
                className="text-center text-sm font-bold uppercase w-[100%] h-[80px] text-gray-600 bg-white hover:bg-gray-200 hover:text-gray-900 p-2 rounded-md"
              >
                Favorite
              </button>
            </div>

            <div className="p-3 bg-gray-100 rounded-md mb-4">
              <b className="text-gray-500">Language</b>
              <Segmented className="mt-4" block options={flags} />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default App;

const flags = [
  {
    label: (
      <div className="p-3">
        <Avatar src="https://nham24.com/assets/images/flag-cambodia.svg" />
        <div className="uppercase">ភាសារខ្មែរ</div>
      </div>
    ),
    value: "kh",
  },
  {
    label: (
      <div className="p-3">
        <Avatar src="https://nham24.com/assets/images/flag-united-kingdom.svg" />
        <div className="uppercase">English</div>
      </div>
    ),
    value: "en",
  },
  {
    label: (
      <div className="p-3">
        <Avatar src="https://nham24.com/assets/images/flag-china.svg" />
        <div className="uppercase">中文</div>
      </div>
    ),
    value: "ch",
  },
];
