
import { firestore, FIR_STORE_TIMESTAMP, generateDocKey } from '../../helper/firebase.helper';

export const getCategoriesAsync = async (observer) => {
  return await firestore
    .collection(`categories`)
    .orderBy("rank", "asc")
    .onSnapshot(observer);
};

export const deleteCategoryAsync = async (id) => {
  return await firestore
    .collection(`categories`)
    .doc(id)
    .delete()
};
export const addCategoryAsync = async (observer) => {
  const formData = observer.formValue;
  const id = formData.id ? formData.id : generateDocKey('categories');

  await firestore
    .collection("categories")
    .doc(id)
    .set(
      {
        ...formData, 
        id,
        created: FIR_STORE_TIMESTAMP,
      },
      { merge: true }
    );
  return id;
};