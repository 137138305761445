
export const ROUTES = {
  // frontend page
  SEARCH: '/search',
  PRODUCT: '/product/:id',
  CLOTHING: '/clothing',
  EVENT: '/event',

  // admin dashboard
  DASHBOARD: '/admin/dashboard',
  DASHBOARD_SLIDE: '/admin/slide',
  DASHBOARD_SETTING: '/admin/setting',
  
  DASHBOARD_PRODUCT: '/admin/product',
  DASHBOARD_PRODUCT_FORM: '/admin/product/form',
  DASHBOARD_PRODUCT_FORM_EDIT: '/admin/product/form/edit/:id',

  DASHBOARD_CATEGORY: '/admin/category',
  DASHBOARD_CATEGORY_FORM: '/admin/category/form',
  DASHBOARD_CATEGORY_FORM_EDIT: '/admin/category/form/edit',
}

export const NAV_MENU_ITEMS = [
  // frontend page
  {
    routeTo: ROUTES.SEARCH,
    label: 'SEARCH',
  },
  {
    routeTo: ROUTES.PRODUCT,
    label: 'PRODUCT',
  },

  // admin dashboard
  {
    routeTo: ROUTES.DASHBOARD,
    label: 'DASHBOARD',
  },
]

