import React from 'react';
import { Link } from "react-router-dom";

function PageNotFound () {

  return (
    <div className='h-[100vh] max-w-[450px] mx-auto bg-red-50'>
      <h2 className='text-center py-4'>Page not found <Link className='text-red-500 underline' to='/'>Back to Home</Link></h2>
    </div>
  )
}

export default PageNotFound;