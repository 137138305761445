import React, { useState, useEffect } from "react";
import MasterPage from "../components/MasterPage";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { getSlidesAsync } from "../../../atoms/slides/slide.api";
const { Dragger } = Upload;

function SlidePage() {
  const [images, setImage] = useState([]);
  const initial = () => {
    getSlidesAsync({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        if (items.length) {
          setImage(items);
        }
      },
      error: (err) => console.log(err.message),
      param: {},
    });
  };

  useEffect(() => {
    initial();
  }, []);
  const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return <MasterPage>
    <div className="p-4 bg-white rounded-lg mb-4 min-h-[50px] flex justify-between items-center">
      <h2 className="font-bold uppercase text-lg">Upload</h2>
    </div>

    <div className="p-4 bg-white rounded-lg h-[80vh] flex">
      <div className="w-[30%]">
        <div className="border rounded-lg h-[100%] mx-2 p-4 hide-scroll cursor-pointer overflow-auto">
          <label>List Images</label>
          <div>
            {images.map((item) => {
              return (
                <img key={item.rank} src={item.img} className='h-[180px] text-center items-center my-4 m-0 rounded-lg bg-red-100 text-red-500' alt='thumbnail' />
              )
            })}
          </div>
        </div>
      </div>
      <div className="w-[70%]">
        <div className="border rounded-lg h-[100%] mx-2 p-4 ">
          <label>Upload</label>
          <div className="w-[100%] h-[90%] mt-4">
            <Dragger multiple={false} {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </div>
        </div>
      </div>
    </div>
  </MasterPage>
}

export default SlidePage;
