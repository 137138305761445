import React, { useState, useEffect } from "react";
import { Table, Avatar, Modal } from "antd";
import MasterPage from "../components/MasterPage";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes/route.constants";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { deletePostAsync, getPostsAsync } from "../../../atoms/posts/post.api";
import { IMAGES } from '../../../constant/images';

function ProductList() {
  const [data, setData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    getPostsAsync({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        if (items.length) {
          setData(items);
        }
      },
      error: (err) => console.log(err.message),
      param: {},
    });
  }, []);

  const remove = (index, form) => {
    deletePostAsync(form.id);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "30%",
    },
    {
      title: "Thumbnail",
      dataIndex: "icon",
      width: "10%",
      render: (index, row) => (
        <div className="flex">
          <Avatar className="hover:cursor-pointer" onClick={() => setPreviewOpen(true)} shape="square" size={50} src={<img src={IMAGES[1]} alt="avatar" />} />
          <Modal
            open={previewOpen}
            title={"Preview"}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            <img
              alt="image_preview"
              style={{ width: "100%" }}
              src={IMAGES[1]}
            />
          </Modal>
        </div>
      ),
    },
    {
      title: "Price($)",
      dataIndex: "price",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (row) => {
        return row ? (
          <div className="border border-green-500 rounded-md p-1 px-4 text-green-500 w-[120px] text-center">
            ដំណើរការ
          </div>
        ) : (
          <div className="border rounded-md p-1 px-4 w-[120px] text-center">បិទដំណើរការ</div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (index, row) => (
        <div className="flex">
          <Link
            to={`${ROUTES.DASHBOARD_PRODUCT_FORM_EDIT.replace(":id", row.id)}`}
          >
            <FiEdit className="text-xl mr-2 text-gray-400 hover:text-blue-700 hover:cursor-pointer" />
          </Link>
          <FiTrash2
            onClick={() => remove(index, row)}
            className="text-xl text-gray-400 hover:text-red-700 hover:cursor-pointer"
          />
        </div>
      ),
    },
  ];
  return (
    <MasterPage>
      <div className="p-4 bg-white rounded-lg mb-4 min-h-[50px] flex justify-between items-center">
        <h2 className="font-bold uppercase text-lg">Post</h2>
        <Link to={ROUTES.DASHBOARD_PRODUCT_FORM}>
          <button className="text-white font-bold cursor-pointer bg-red-500 p-2 rounded-md  hover hover:bg-red-400">
            Add New
          </button>
        </Link>
      </div>

      <div className="p-4 bg-white rounded-lg h-[80vh]">
        <Table
          scroll={{ y: 500 }}
          fixed="top"
          rowKey={(record) => record.id}
          x={400}
          columns={columns}
          dataSource={data}
        />
      </div>
    </MasterPage>
  );
}

export default ProductList;
