
import { firestore, FIR_STORE_TIMESTAMP, generateDocKey } from '../../helper/firebase.helper';

export const getSlidesAsync = async (observer) => {
  return await firestore
    .collection(`slides`)
    .orderBy("rank", "asc")
    .onSnapshot(observer);
};

export const deleteSlideAsync = async (id) => {
  return await firestore
    .collection(`slides`)
    .doc(id)
    .delete()
};
export const addSlideAsync = async (observer) => {
  const formData = observer.formValue;
  const id = formData.id ? formData.id : generateDocKey('slides');

  await firestore
    .collection("slides")
    .doc(id)
    .set(
      {
        ...formData, 
        id,
        created: FIR_STORE_TIMESTAMP,
      },
      { merge: true }
    );
  return id;
};