import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai"
import { ROUTES } from "../../routes/route.constants";
import { IMAGES } from '../../constant/images';

function SearchPage() {
  return (
    <div className='h-[100vh] max-w-[450px] mx-auto'>
      <div className='mt-4 bg-white px-4 py-4 flex justify-between items-center sticky top-0'>
        <span className='text-red-400 uppercase text-md p-3 bg-red-100 font-bold rounded-full text-center cursor-pointer items-center'>
          <Link to='/'>
            <AiOutlineArrowLeft className="text-sm" />
          </Link>
        </span>
        <Input className="ml-2 p-3 border border-red-200 focus:outline-none focus:ring focus:ring-red-300 text-lg text-gray-500" type='text' />
      </div>

      <div className='mt-4 px-4'>
        {[{}, {},{}, {},{}, {}].map((item, index) => {
          return (
              <div key={index} className='h-[150px] w-[100%] hover:cursor-pointer flex my-3'>
                <div className="w-[40%]">
                  <Link key={index} to={`${ROUTES.PRODUCT.replace(':id', index)}`}>
                    <img className='rounded-md h-[150px] w-[100%]' src={IMAGES[1]} alt='thumbnail-logo' />
                  </Link>
                </div>
                <div className="w-[60%] px-2">
                  <Link to={`${ROUTES.PRODUCT.replace(':id', index)}`}>
                    <h6>ម៉ូតថ្មីទើបចូលស្តុក (ស្តុកថ្មី)</h6>
                      <div className='my-1'>
                        <span className='text-red-500 font-bold'>$58.99</span> <span className='text-gray-400 ml-4 line-through'>$70.00</span>
                      </div>
                    <p className="text-sm text-gray-700">
                      អាច​ប្រើ​ប្រាស់​ភ្ជាប់ជាមួយ: ខ្សែក្រវ៉ាត់,ចង្កេះខោ ឬហោប៉ៅបាន
                    </p>
                  </Link>

                  <div className='flex justify-end items-center'>
                    <button className='text-red-400 bg-red-50 hover:bg-red-500 hover:text-white text-sm p-2 rounded-md text-center mr-4'>
                      <AiOutlinePlus />
                    </button>
                    <button className='text-red-400 bg-red-50 hover:bg-red-500 hover:text-white text-sm p-2 rounded-md text-center'>បញ្ជារទិញ</button>
                  </div>
                </div>
              </div>

          )
        })}
      </div>
    </div>
  )
}

export default SearchPage;