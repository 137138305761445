import React, { useEffect } from "react";
import { AiOutlineArrowLeft, AiOutlineShoppingCart } from "react-icons/ai";
import { BsFillShareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Carousel } from "antd";

function ProductDetailPage() {
  useEffect(()=> {
    window.scrollTo(0, 0);
  },[])

  return (
    <div className='h-[100vh] max-w-[450px] mx-auto'>
      <div className='transparent px-2 flex justify-between items-center my-4'>
        <span className='text-red-400 uppercase text-md p-3 bg-red-100 font-bold rounded-full text-center items-center cursor-pointer'>
          <Link to='/'>
            <AiOutlineArrowLeft className="text-sm" />
          </Link>
        </span>
        <span className='text-red-400 uppercase text-md p-3 bg-red-100 font-bold rounded-full text-center items-center cursor-pointer'>
          <BsFillShareFill className="text-sm" />
        </span>
      </div>
      
      <Carousel autoplay className='w-[100%] cursor-pointer'>
        <div className=''>
          <img src='https://storage.googleapis.com/dobpi/782ef95a-073b-4b47-9a75-1520923cfaee.jpeg' className='h-[350px] text-center items-center m-0  bg-red-100 text-red-500' alt='thumbnail' />
        </div>
        <div className=''>
          <img src='https://storage.googleapis.com/dobpi/64534cb0-6fe7-44f7-8fac-b4fa368bd41c.jpg' className='h-[350px] text-center items-center m-0  bg-red-100 text-red-500' alt='thumbnail' />
        </div>
        <div className=''>
          <img src='https://storage.googleapis.com/dobpi/44563649-5811-4708-87e9-96233f1d23bd.jpg' className='h-[350px] text-center items-center m-0  bg-red-100 text-red-500' alt='thumbnail' />
        </div>
        <div className=''>
          <img src='https://storage.googleapis.com/dobpi/8945f590-e8d9-46d7-be90-4cc8a3eecd0a.jpg' className='h-[350px] text-center items-center m-0  bg-red-100 text-red-500' alt='thumbnail' />
        </div>
      </Carousel>

      <div className='m-4 h-[800px]'>
        <h6 className="font-bold text-lg">ម៉ូតថ្មីទើបចូលស្តុក</h6>
        <div className='my-2'>
          <span className='text-red-500 text-lg font-bold'>$58.99</span> <span className='text-gray-400 ml-4 line-through'>$70.00</span>
        </div>
        <div className='my-2'>
          <b className="text-gray-700 text-md">ពិពណ៌នា</b>
          <p className="my-4 text-sm text-gray-700">
            𝗣𝗨𝗟𝗢𝗞𝗔 ប្រណិត​ភាព​នែភាព​ទាន់សម័យ
            ផលិតផល 𝗣𝗨𝗟𝗢𝗞𝗔 100%
            រចនារូបរាងស្អាត ម៉ូដថ្មី ជាមួយស្បែកស្អាត
            កាបូប​ដាក់​ទូរស័ព្ទ (ដាក់ចង្កេះ)
          </p>
          <p className="text-sm text-gray-700 mt-4">
            អាច​ប្រើ​ប្រាស់​ភ្ជាប់ជាមួយ: ខ្សែក្រវ៉ាត់,ចង្កេះខោ ឬហោប៉ៅបាន
            ដាក់ទូរស័ព្ទបាន 2 គ្រឿង សាកសមជាមួយបុរសទាន់សម័យ
          </p>
        </div>
      </div>

      <div className="fixed w-[100%] z-10 bottom-0 p-3">
        <div className="max-w-[450px] h-[50px]  bottom-0 flex justify-between space-x-4">
          <button className="w-[20%] text-gray-500 uppercase bg-white text-md p-3 border border-red-200 font-bold rounded-lg cursor-pointer">
            <AiOutlineShoppingCart className="text-xl  items-center mx-auto text-red-400" />
          </button>
          <button className="w-[80%] text-red-400 uppercase text-md p-3 bg-red-100 font-bold rounded-lg text-center items-center cursor-pointer">
            កម្មង់ឥលូវនេះ
          </button>
        </div>
      </div>

    </div>
  )
}

export default ProductDetailPage;