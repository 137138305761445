import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/App.css';
import App from './views/frontend/App';
import { BrowserRouter } from 'react-router-dom';
import RequestRoute from './routes/request.route';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RequestRoute>
        <App />
      </RequestRoute>
    </BrowserRouter>
  </React.StrictMode>
);

