import React from "react";
import MasterPage from "../components/MasterPage";
import { Form, Input, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";



function CategoryForm() {
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <MasterPage>
      <Form
        name="category"
        layout="vertical"
        // initialValues={{
        //   remember: true,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="p-4 bg-white rounded-lg mb-4 min-h-[50px] flex justify-between items-center">
          <h2 className="font-bold uppercase text-lg">Add Category</h2>
          <div>
            <button onClick={() => navigate(-1)} className="text-white font-bold min-w-[80px] cursor-pointer bg-red-500 p-2 rounded-md  hover hover:bg-red-400 mr-4">
              Cancel
            </button>
            <button
              htmltype="submit"
              className="text-white font-bold min-w-[80px] cursor-pointer bg-blue-500 p-2 rounded-md  hover hover:bg-blue-400"
            >
              Save
            </button>
          </div>
        </div>

        <div className="p-4 bg-white rounded-lg h-[80vh]">
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </MasterPage>
  );
}

export default CategoryForm;
