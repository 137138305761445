export const loginUser = (username, password) => {
  // if(username === "admin@khmerdressup.com" && password === "085504645"){
    if(username === "admin" && password === "123456"){
    localStorage.setItem("authorization", true);
    localStorage.setItem("username", "super_user");
    localStorage.setItem("lang", "en");
    localStorage.setItem("mode", "light");
    return true;
  }else{
    return false;
  }
}

export const logoutUser = () => {
  localStorage.clear();
  window.location.reload();
}