
import { firestore, FIR_STORE_TIMESTAMP, generateDocKey } from '../../helper/firebase.helper';

export const getPostsAsync = async (observer) => {
  return await firestore
    .collection(`posts`)
    .orderBy('created', 'desc')
    .onSnapshot(observer);
};

export const getPostAsync = async (observer) => {
  const { postId } = observer.param;
  
  if(postId){
    return await firestore
    .collection(`posts`)
    .where('id', '==', postId)
    .onSnapshot(observer);
  }
};

export const deletePostAsync = async (id) => {
  return await firestore
    .collection(`posts`)
    .doc(id)
    .delete()
};
export const addPostsAsync = async (observer) => {
  const formData = observer.formValue;
  const id = formData.id ? formData.id : generateDocKey('posts');

  await firestore
    .collection("posts")
    .doc(id)
    .set(
      {
        ...formData, 
        id,
        created: FIR_STORE_TIMESTAMP,
      },
      { merge: true }
    );
  return id;
};