import { Link } from "react-router-dom";
import MasterPage from "./components/MasterPage";
import { ROUTES } from '../../routes/route.constants';

function Layout() {
  return (
    <MasterPage>
       <div className="mb-4 flex flex-row">
        <div className="p-4 bg-white rounded-lg w-[250px] h-[100px] flex justify-between">
          <div className="flex flex-col justify-between">
            <label className="uppercase font-semibold	 text-red-500">Total Post</label> 
            <Link to={ROUTES.DASHBOARD_PRODUCT}>
              <label className="text-sm uppercase  hover:cursor-pointer">View All</label> 
            </Link>

          </div>
          <h1 className="text-4xl text-justify mt-3">23</h1>
        </div>

        <div className="p-4 bg-white rounded-lg w-[250px] h-[100px] flex justify-between ml-4">
          <div className="flex flex-col justify-between">
            <label className="uppercase font-semibold	 text-red-500">Total Category</label> 
            <Link to={ROUTES.DASHBOARD_CATEGORY}>
              <label className="text-sm uppercase  hover:cursor-pointer">View All</label> 
            </Link>
          </div>
          <h1 className="text-4xl text-justify mt-3">8</h1>
        </div>

        <div className="p-4 bg-white rounded-lg w-[250px] h-[100px] flex justify-between ml-4">
          <div className="flex flex-col justify-between">
            <label className="uppercase font-semibold	 text-red-500">Total Purchase</label> 
            <label className="text-sm uppercase  hover:cursor-pointer">View All</label> 
          </div>
          <h1 className="text-4xl text-justify mt-3">10</h1>
        </div>
       </div>
    </MasterPage>
  )
}
export default Layout;

