import React, { useState } from "react";
import { AiOutlineMenuUnfold, AiOutlineLogout, AiFillSetting } from "react-icons/ai";
import { BsFillImageFill } from "react-icons/bs";
import { BiCategoryAlt, BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { logoutUser } from "../../../helper/index.helper";
import { FiMonitor } from "react-icons/fi"
import { ROUTES } from "../../../routes/route.constants";

function MasterPage({children}) {
  const [mini, setMini] = useState(false);

  return (
    <div className="bg-white h-[100vh] flex">
      <div
        className={`flex flex-col justify-between min-h-[100vh] bg-red-600 text-center ${
          mini ? "w-[250px]" : "w-[60px]"
        }`}
      >
        <div className="w-[100%] mt-4 z-10">
          <div className="text-white flex justify-between font-bold mt-2 cursor-pointer bg-red-800 p-2 rounded-md mx-2">
            {mini ? (
              <>
                <AiOutlineMenuUnfold
                  className="text-2xl"
                  onClick={() => setMini(!mini)}
                />
                <h2 className="ml-4 whitespace-nowrap">Khmer Dress UP</h2>
              </>
            ) : (
              <AiOutlineMenuUnfold
                className="text-2xl"
                onClick={() => setMini(!mini)}
              />
            )}
          </div>
        </div>
        <div>
          {items.map((item, index) => (
            <Link
              to={item.route}
              key={index}
              className="text-white flex items-center text-left font-bold mt-2 cursor-pointer bg-red-800 p-2 rounded-md mx-2 hover hover:bg-red-500"
            >
              {mini ? (
              <>
                {item.icon} <span className="ml-3 whitespace-nowrap">{item.title}</span>
              </>
            ) : (
             <>
              {item.icon}
             </>
            )}
            </Link>
          ))}
        </div>

        <div className="w-[100%] mb-4">
          <div className="text-white flex justify-between font-bold mt-2 cursor-pointer bg-red-800 p-2 rounded-md mx-2 ">
            {mini ? (
              <>
                <AiOutlineLogout onClick={()=> logoutUser()} className="text-2xl" />
                <h2 onClick={()=> logoutUser()}>Logout</h2>
              </>
            ) : (
              <AiOutlineLogout onClick={()=> logoutUser()} className="text-2xl" />
            )}
          </div>
        </div>
      </div>
      
      <div className="w-full min-h-[100vh] px-8 py-6 bg-slate-100	">
        {children}
      </div>
    </div>
  );
}
export default MasterPage;

const items = [
  { title: "Product", icon: <FiMonitor className="text-2xl" />, route: ROUTES.DASHBOARD },
  { title: "Product", icon: <BiEdit className="text-2xl" />, route: ROUTES.DASHBOARD_PRODUCT },
  { title: "Category", icon:<BiCategoryAlt className="text-2xl" />, route: ROUTES.DASHBOARD_CATEGORY },
  { title: "Slide", icon: <BsFillImageFill className="text-2xl" />, route: ROUTES.DASHBOARD_SLIDE},
  { title: "Setting", icon: <AiFillSetting className="text-2xl" />, route: ROUTES.DASHBOARD_SETTING },
];
