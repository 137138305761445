import React, { useEffect, useState } from "react";
import PageNotFound from "../components/PageNotFound";
import App from "../views/frontend/App";
import SearchPage from '../views/frontend/Search';
import ProductDetailPage from '../views/frontend/ProductDetail';
import Layout from "../views/admin/Layout";
import ProductList from "../views/admin/post";
import CategoryList from "../views/admin/category";
import CategoryForm from "../views/admin/category/form";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES } from './route.constants';
import ProductForm from "../views/admin/post/form";
import LoginPage from "../views/admin/auth/Login";
import SlidePage from "../views/admin/slide";

export const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const [authorization , seAuthorization] = useState(false);
  useEffect(()=> {
    const authStatus = localStorage.getItem("authorization");
    if(authStatus){
      seAuthorization(true)
    }else{
      navigate('/login')
    }
  },[navigate])
  
  return authorization && children;
};

export const NotRequireAuth = ({ children }) => {

  return children;
}

const RequestRoute = () => {
  return (
    <React.Suspense fallback={<h4>Loading...</h4>}>
      <Routes>
        <Route path="/" element={<App />} />

        {/* public route */}
        <Route
          path={ROUTES.SEARCH}
          element={
            <NotRequireAuth>
              <SearchPage />
            </NotRequireAuth>
          }
        />
        <Route
          path={ROUTES.PRODUCT}
          element={
            <NotRequireAuth>
              <ProductDetailPage />
            </NotRequireAuth>
          }
        />
        {/* private route */}
        <Route
          path={ROUTES.DASHBOARD}
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        />

        <Route
          path={ROUTES.DASHBOARD_PRODUCT}
          element={
            <RequireAuth>
              <ProductList />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.DASHBOARD_CATEGORY}
          element={
            <RequireAuth>
              <CategoryList />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.DASHBOARD_CATEGORY_FORM}
          element={
            <RequireAuth>
              <CategoryForm/>
            </RequireAuth>
          }
        />
        
        <Route
          path={ROUTES.DASHBOARD_PRODUCT}
          element={
            <RequireAuth>
              <ProductList />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.DASHBOARD_PRODUCT_FORM}
          element={
            <RequireAuth>
              <ProductForm />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.DASHBOARD_PRODUCT_FORM_EDIT}
          element={
            <RequireAuth>
              <ProductForm />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.DASHBOARD_SLIDE}
          element={
            <RequireAuth>
              <SlidePage />
            </RequireAuth>
          }
        />
        
        <Route path="/login" element={<LoginPage />} />
        <Route path="/not-found" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  )
}
export default RequestRoute;