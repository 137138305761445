import React from "react";
function LoadingPage() {
  return (
    <div className="bg-white h-[100vh]">
      <div className="max-w-[450px] mx-auto">
        <div
          role="status"
          className="max-w-md p-4 space-y-4 divide-y divide-red-200 rounded shadow animate-pulse dark:divide-red-700 md:p-6 dark:border-red-700"
        >
          {/* head menu */}
          <div role="status" className="flex items-center justify-center h-10 bg-red-300 rounded-lg animate-pulse dark:bg-red-700 w-full" />

          {/* slide  */}
          <div role="status" className="flex items-center justify-center h-56 bg-red-300 rounded-lg animate-pulse dark:bg-red-700 w-full">
              <span>កំពុងទាញទិន្ន័យ...</span>
          </div>
          
          {/* list  */}
          <div className="flex items-center justify-between mt-4">
            <div className="mt-4">
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-red-200 rounded-full dark:bg-red-700"></div>
            </div>
            <div className="h-2.5 bg-red-300 rounded-full dark:bg-red-700 w-12"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
