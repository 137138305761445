import React, { useState, useEffect } from "react";
import MasterPage from "../components/MasterPage";
import { Table, Modal, Input, InputNumber, Checkbox } from "antd";
import {
  addCategoryAsync,
  deleteCategoryAsync,
  getCategoriesAsync,
} from "../../../atoms/categories/category.api";
import { FiEdit, FiTrash2 } from "react-icons/fi";
const { TextArea } = Input;
const defaultField = {
  title: "",
  description: "",
  status: true,
  rank: 1,
  icon: "N/A",
};

function CategoryList() {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [form, setForm] = useState(defaultField);
  const [id, setId] = useState('');

  const initial = () => {
    getCategoriesAsync({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        if (items.length) {
          setData(items);
        }
      },
      error: (err) => console.log(err.message),
      param: {},
    });
  };

  useEffect(() => {
    initial();
  }, []);

  const edit = (index, form) => {
    setId(form.id)
    setForm(form)
    setVisible(true);
  };

  const remove = (index, form) => {
    deleteCategoryAsync(form.id)
  };

  const save = () => {
    addCategoryAsync({
      next: () => {},
      error: (err) => console.log(err.message),
      formValue: { ...form },
    });
    setForm(defaultField);
    setVisible(false);
    setId("")
    initial();
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "40%",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      width: "10%",
    },
    {
      title: "Rank",
      dataIndex: "rank",
      width: "10%",
      sorter: (a, b) => a.rank - b.rank,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (row) => {
        return row ? <span className="border border-green-500 rounded-md p-1 px-4 text-green-500">Active</span> : <span className="border rounded-md p-1 px-4">Inactive</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (index, row) => (
        <div className="flex">
          <FiEdit onClick={()=>edit(index, row)} className="text-xl mr-2 text-gray-400 hover:text-blue-700 hover:cursor-pointer" />
          <FiTrash2 onClick={()=>remove(index, row)} className="text-xl text-gray-400 hover:text-red-700 hover:cursor-pointer" />
        </div>
      ),
    },
  ];

  return (
    <MasterPage>
      <div className="p-4 bg-white rounded-lg mb-4 min-h-[50px] flex justify-between items-center">
        <h2 className="font-bold uppercase text-lg">Category</h2>
        <button
          onClick={() => setVisible(true)}
          className="text-white font-bold cursor-pointer bg-red-500 p-2 rounded-md min-w-[80px] hover hover:bg-red-400"
        >
          Add New
        </button>
      </div>

      <div className="p-4 bg-white rounded-lg h-[80vh]">
        <Table
          scroll={{ y: 500 }}
          fixed="top"
          rowKey={(record) => record.id}
          x={400}
          columns={columns}
          dataSource={data}
        />
      </div>

      {visible && (
        <Modal
          key="categoryForm"
          footer={null}
          width={550}
          title={`${id ? "Update" : "Add"} Category`}
          open={visible}
          onCancel={() => {
            setVisible(false); 
            setId("");
            setForm(defaultField)
          }}
        >
          <div className="my-4">
            <label>Title</label>
            <Input
              size="large"
              defaultValue={form.title}
              onChange={(val) =>
                setForm((form) => ({ ...form, title: val.target.value }))
              }
              placeholder="Title"
            />
          </div>
          <div className="flex justify-between items-center my-4">
            <div className="w-[80%]">
              <label>Rank</label>
              <InputNumber
                defaultValue={form.rank}
                onChange={(val) => setForm((form) => ({ ...form, rank: val }))}
                className="w-[100%]"
                size="large"
                min={1}
                type="number"
              />
            </div>
            <Checkbox
              defaultChecked={form.status}
              onChange={(val) =>
                setForm((form) => ({ ...form, status: val.target.checked }))
              }
              className="w-[20%] flex justify-end mt-5"
              size="large"
            >
              Active
            </Checkbox>
          </div>
          <div className="my-4">
            <label>Description</label>
            <TextArea
              defaultValue={form.description}
              onChange={(val) =>
                setForm((form) => ({ ...form, description: val.target.value }))
              }
              rows={4}
              size="large"
              placeholder="Description"
            />
          </div>

          <div className="flex justify-end">
            <button
              onClick={() => {
                setVisible(false); 
                setId("");
                setForm(defaultField)
              }}
              className="font-bold min-w-[80px] cursor-pointer text-red-500 hover:text-white border border-red-200 p-2 rounded-md  hover hover:bg-red-200"
            >
              Cancel
            </button>
            <button
              onClick={() => save()}
              className="text-white font-bold min-w-[80px] cursor-pointer bg-red-500 p-2 rounded-md  hover hover:bg-red-400 ml-4"
            >
              Save
            </button>
          </div>
        </Modal>
      )}
    </MasterPage>
  );
}

export default CategoryList;
