import React, { useState, useEffect } from "react";
import MasterPage from "../components/MasterPage";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Col,
  Row,
  Modal,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { getCategoriesAsync } from "../../../atoms/categories/category.api";
import { ROUTES } from "../../../routes/route.constants";
import { addPostsAsync, getPostAsync } from "../../../atoms/posts/post.api";
const { TextArea } = Input;

const defaultField = {
  title: "",
  description: "",
  status: true,
  images: [],
  thumbnail: "N/A",
  price: 0,
  discount: 0,
  salePrice: 0,
  category: [],
  category_info: [],
};

function ProductForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form, setForm] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [categories, setCategories] = useState([]);
  // useEffect(()=> {
  //   const { price, discount } = form;
  //   if(price && discount){
  //     const salePrice = price * 0.10;
  //     console.log("[salePrice]", salePrice)
  //     setForm((form) => ({ ...form, salePrice: salePrice }))
  //   }
  // },[form]);

  useEffect(() => {
    if (id) {
      getPostAsync({
        next: (querySnapshot) => {
          const items = querySnapshot.docs.map((docSnapshot) =>
            docSnapshot.data()
          );
          if (items.length) {
            setForm(items[0])
          }
        },
        error: (err) => console.log(err.message),
        param: { postId: id },
      });
    }else{
      setForm(defaultField)
    }
  }, [id]);

  const initial = () => {
    getCategoriesAsync({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        if (items.length) {
          const mData = items.map((item) => {
            return { value: item.id, label: item.title, info: item };
          });
          setCategories(mData);
        }
      },
      error: (err) => console.log(err.message),
      param: {},
    });
  };

  useEffect(() => {
    initial();
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      +<div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const save = () => {
    addPostsAsync({
      next: () => {},
      error: (err) => console.log(err.message),
      formValue: { ...form },
    });
    setForm(defaultField);
    navigate(ROUTES.DASHBOARD_PRODUCT);
  };

  return (
    <MasterPage>
      <Form name="category" layout="vertical" autoComplete="off">
        <div className="p-4 bg-white rounded-lg mb-4 min-h-[50px] flex justify-between items-center">
          <h2 className="font-bold uppercase text-lg">{id ? "Update" : "Add" } Product</h2>
          <div>
            <button
              onClick={() => navigate(-1)}
              className="font-bold min-w-[80px] cursor-pointer text-red-500 hover:text-white border border-red-200 p-2 rounded-md  hover hover:bg-red-200"
            >
              Cancel
            </button>
            <button
              onClick={() => save()}
              className="text-white font-bold min-w-[80px] cursor-pointer bg-red-500 p-2 rounded-md  hover hover:bg-red-400 ml-4"
            >
              Save
            </button>
          </div>
        </div>

        <div className="p-4 bg-white rounded-lg h-[80vh]">
          <Row gutter={24}>
            <Col span={16}>
              <Row gutter={24}>
                <Col span={24} className="mb-4">
                  <label>Title</label>
                  <Input
                    size="large"
                    value={form.title}
                    onChange={(val) =>
                      setForm((form) => ({ ...form, title: val.target.value }))
                    }
                    placeholder="Title"
                  />
                </Col>
                <Col span={4} className="mb-4">
                  <label>Price $</label>
                  <InputNumber
                    value={form.price}
                    onChange={(val) =>
                      setForm((form) => ({ ...form, price: val }))
                    }
                    className="w-[100%]"
                    size="large"
                    min={1}
                    type="number"
                  />
                </Col>
                <Col span={4} className="mb-4">
                  <label>Discount(%)</label>
                  <InputNumber
                    value={form.discount}
                    onChange={(val) =>
                      setForm((form) => ({ ...form, discount: val }))
                    }
                    className="w-[100%]"
                    size="large"
                    min={1}
                    type="number"
                  />
                </Col>
                <Col span={4} className="mb-4">
                  <label>Sale Price</label>
                  <Input
                    value={form.salePrice}
                    className="w-[100%]"
                    size="large"
                    type="number"
                    disabled
                  />
                </Col>
                <Col span={12} className="mb-4">
                  <label>Category</label>
                  <Select
                    mode="tags"
                    size="large"
                    placeholder="Please select"
                    value={form.category}
                    onChange={(val, data) => {
                      console.log("[data]", data);
                      setForm((form) => ({
                        ...form,
                        category: val,
                      }));
                      setForm((form) => ({
                        ...form,
                        category_info: data,
                      }));
                    }}
                    style={{ width: "100%" }}
                    options={categories}
                  />
                </Col>
                <Col span={24} className="mb-4">
                  <label>Description</label>
                  <TextArea
                    size="large"
                    rows={4}
                    value={form.description}
                    onChange={(val) =>
                      setForm((form) => ({
                        ...form,
                        description: val.target.value,
                      }))
                    }
                    placeholder="description"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8} className="mb-4">
              <label>Upload</label>
              <div className="border border-gray-300 bg-gray-50 min-h-[270px] rounded-lg p-4">
                <ImgCrop>
                  <Upload
                    className="my-2"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList.length >= 6 ? null : uploadButton}
                  </Upload>
                </ImgCrop>

                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={() => setPreviewOpen(false)}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </MasterPage>
  );
}

export default ProductForm;

export const IMAGES = [
  {
    uid: "-1",
    name: "image.png",
    status: "done",
    url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
  {
    uid: "-2",
    name: "image.png",
    status: "done",
    url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
  {
    uid: "-3",
    name: "image.png",
    status: "done",
    url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
  {
    uid: "-4",
    name: "image.png",
    status: "done",
    url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
